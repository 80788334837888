<template>
    <div class="inner_pages" id="form_index">
        <div class="respective_content">
            <div class="acton_header">
                <div class="left_section">
                    <h2>Forms</h2>
                    <div class="search_area">
                        <input type="text" placeholder="Search..." @input="isTyping = true" v-model.trim="search"/>
                        <button class="search_btn">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
                <div class="right_section">
                    <ul>
                        <li @click="assetFolder = !assetFolder" v-tooltip="assetFolder ? 'Close Folders' : 'Open Folders'" position="bottom"><img src="@/assets/images/folder4.svg"></li>
                        <li @click="sortForm = !sortForm;" v-tooltip="`Sort Forms Dashboard`" position="bottom"><img src="@/assets/images/slider3.svg"></li>
                        <li v-if="companyUserCan('create', 'forms')" @click="importForm = !importForm" v-tooltip="`Import a shared form`" position="bottom"><img src="@/assets/images/import.svg"></li>
                        <li class="add_btn light pointer" @click="newForm = true" v-if="companyUserCan('create', 'forms')"><i class="fas fa-plus-circle"></i>Create Form</li>
                    </ul>
                </div>
            </div>
            <div class="result_wpr new">
                <div class="actions">
                    <ul class="left">
                        <li class="p-0" v-show="selectedForm.length || showContent">
                            <label for="check" class="checkbox">
                                <h5 class="p-0">{{ selectedForm.length ? `${selectedForm.length} Selected` : `Select thumbnails for bulk action`}}</h5>
                            </label>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="bulkActionDropdown = !bulkActionDropdown" v-click-outside="closeBulkActionDropdown" v-if="selectedForm.length">
                            Bulk Action<i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="bulkActionDropdown ? 'active' : ''">
                                <ul>
                                    <li v-if="companyUserCan('create', 'forms')" @click="handleDuplicateForm(selectedForm)">Duplicate</li>
                                    <li v-if="companyUserCan('delete', 'forms')" class="danger" @click="handleDeleteForm(selectedForm)">Delete</li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops contacts-tabs tutorial_btn" @click="tutorialList = !tutorialList;" v-click-outside="closeTutorialListDropdown">
                            <label>Tutorials</label><img src="@/assets/images/play.svg">
                            <div class="dropdown_wpr" :class="tutorialList ? 'active' : ''">
                                <ul>
                                    <li @click="tutorial = true;">How to use the forms feature</li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                    <ul class="right">
                        <li class="optionDrops contacts-tabs" @click="actionList = !actionList"  v-click-outside="closeSortingDropdown">
                            {{ filterTitle }} <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="actionList ? 'active' : ''">
                                <ul>
                                    <li v-for="(filter, f) in filters" :key="f" :class="{'active' : filter.title == filterTitle}" @click="sortingFilter(filter)">
                                        {{ filter.title }}
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                            Show {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                            <page-filter v-model="perPageFilter" :type="1" :is-dropdown="true" ref="form-per-page-filter" />
                        </li>
                        <li class="filter_btn" @click="mobile_filter = true;">
                            <span></span>
                            <span></span>
                            <span></span>
                        </li>
                    </ul>
                </div>
                <div class="listing_loader" v-show="formLoader"><quote-loader /></div>
                <div class="action_content" v-show="!formLoader">
                    <asset-folder v-model="assetFolder" title="Form" asset-type="forms" :folder-params="folderParams" :refresh-folder="refreshFolder" :asset-folder-watcher="assetFolderWatcher" :is-last-breadcrumb="isLastBreadcrumb" :section-breadcrumb="folderBreadcrumb['forms']"/>
                    <ul class="playbook_list"  v-if="!isArchived || folderParams.parent_id > 0">
                        <li  v-for="(form, f) in forms.data" :key="f">
                            <div class="plybook_item" @mouseenter="showAction(true)" @mouseleave="showAction(false)">
                                <div class="item_img" :class="{active : selectedForm.includes(form.id)}">
                                    <img v-if="form.dashboard_thumb" :src="form.dashboard_thumb" class="img">
                                    <img  v-else src="@/assets/images/thumb/default-form.svg" class="img">
                                    <span v-if="companyUserCan('update', 'forms') && !form.is_shared_with_contact" class="replace_img" @click="editThumbnail = true; activeForm = form;">
                                        <img src="@/assets/images/image.svg">
                                    </span>
                                    <ul class="statistics">
                                        <li>
                                            <label :for="`form-${form.id}`" class="checkbox" v-if="!form.is_shared_with_contact">
                                                <input type="checkbox" :id="`form-${form.id}`" :value="form.id" v-model="selectedForm" hidden>
                                                <span><i class="fas fa-check"></i></span>
                                            </label>
                                        </li>
                                        <li>{{ form.response_count ? form.response_count : 0 }} Form Submissions</li>
                                        <li>{{ form.submission_rate ? (form.submission_rate > 100 ? 100 : form.submission_rate) : 0 }}% Submission Rate</li>
                                        <li>{{ form.page_views ? form.page_views : 0 }} Unique Views</li>
                                    </ul>
                                </div>
                                <div class="flex" v-tooltip="form.survey_name">
                                    <router-link v-if="companyUserCan('update', 'forms') && !form.is_shared_with_contact" :to="{ name: 'FormEdit', params: { form: form.id }}" class="item_ttl">
                                        {{ form.survey_name }}
                                    </router-link>
                                    <a v-else class="item_ttl">{{ form.survey_name }}</a>
                                </div>
                                <div class="item_info">
                                    <div class="member pointer" @click="formRespondent = true; activeForm = form" v-tooltip="`Clients Assigned to this Form`">
                                        <img src="@/assets/images/member.svg">
                                        {{ form.response_count ? form.response_count : 0}} Responses
                                    </div>
                                    <ul>
                                        <li v-if="companyUserCan('update', 'forms')" class="bg-transparent" v-tooltip="form.enabled == 1 ? 'Disable Form' : 'Enable Form'">
                                            <label :for="`page-${form.id}`" class="switch_option capsule_btn" v-if="!form.is_shared_with_contact">
                                                <input type="checkbox" :id="`page-${form.id}`" :checked="form.enabled == 1" @change="handleEnableDisable($event, form)" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </li>
                                        <li @click="formLink = true; activeForm = form"><span v-tooltip="`Live Form Link`"><i class="fas fa-link"></i></span></li>
                                        <li @click="moreOption(form.id)">
                                            <span v-tooltip="`Additional Actions`"><i class="fas fa-ellipsis-v"></i></span>
                                            <ul class="row_option mr-4 py-1" :class="{'active' : moreOptionDropdown ==  form.id}">
                                                <li v-if="companyUserCan('update', 'forms') && !form.is_shared_with_contact">
                                                    <router-link :to="{ name: 'FormEdit', params: { form: form.id }}">Edit Form</router-link>
                                                </li>
                                                <li @click="editThumbnail = true; activeForm = form;" v-if="companyUserCan('update', 'forms') && !form.is_shared_with_contact">Edit Thumbnail Image</li>
                                                <li @click="renameForm = true; activeForm = form" v-if="companyUserCan('update', 'forms') && !form.is_shared_with_contact">Rename Form</li>
                                                <li @click="sendForm = true; activeForm = form" v-if="!form.is_shared_with_contact">Send Form</li>
                                                <li @click="showAnalytics = true; activeForm = form">Show Analytics</li>
                                                <li @click="formLink = true; activeForm = form" v-if="!form.is_shared_with_contact">Form Link</li>
                                                <li @click="handleShareForm(form)" v-if="!form.is_shared_with_contact">Share Form</li>
                                                <li @click="moveForm = true; activeForm = form" v-if="!form.is_shared_with_contact">Move to Folder</li>
                                                <li @click="shareLocation = true; activeForm = form" v-if="!form.is_shared_with_contact && (isSuperUser || user.is_company_location == 1)">Share to Location</li>
                                                <li v-if="companyUserCan('update', 'forms') && !form.is_shared_with_contact" @click="editDeliveryMessage = true; activeForm = form;">Edit Delivery Message</li>
                                                <li v-if="companyUserCan('create', 'forms') && !form.is_shared_with_contact" @click="handleDuplicateForm([form.id])">Duplicate</li>
                                                <li v-if="companyUserCan('delete', 'forms') && !form.is_shared_with_contact" @click="handleDeleteForm([form.id])" class="danger">Delete</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="empty_box" v-if="!forms.total">
                        <img src="@/assets/images/empty_state.svg">
                        <h4>Looks like you don't have any Forms yet. Click the play button to get started.</h4>
                    </div>
                </div>
                <div class="list_footer">
                    <ul>
                        <li>{{ forms.from ? forms.from : 0 }} - {{ forms.to ? forms.to : 0 }} of {{ forms.total ? forms.total : 0 }}</li>
                    </ul>
                </div>
                <div class="pagination" v-show="forms.total && (!isArchived || folderParams.parent_id > 0)">
                    <pagination v-model="page" :pages="forms.last_page" @update:modelValue="handlePagination" />
                </div>
            </div>
            <div class="header_filter" :class="{'show' : mobile_filter}">
                <button class="close_btn" @click="mobile_filter = false;"><i class="fas fa-times"></i></button>
                <div class="top_area">
                    <h3>Actions</h3>
                    <ul>
                        <li @click="assetFolder = !assetFolder" v-tooltip="assetFolder ? 'Close Folders' : 'Open Folders'" position="bottom"><img src="@/assets/images/folder4.svg"></li>
                        <li @click="sortForm = !sortForm;" v-tooltip="`Sort Forms Dashboard`" position="bottom"><img src="@/assets/images/slider3.svg"></li>
                        <li v-if="companyUserCan('create', 'forms')" @click="importForm = !importForm" v-tooltip="`Import a shared form`" position="bottom"><img src="@/assets/images/import.svg"></li>
                    </ul>
                </div>
                <div class="action_area">
                    <h3 class="sub_heading2">Filters</h3>
                    <div class="search_area">
                        <input type="text" placeholder="Search..." @input="isTyping = true" v-model.trim="search"/>
                        <button class="search_btn">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                    <ul>
                        <li v-show="selectedForm.length || showContent">
                            <label for="check" class="checkbox">
                                <h5 class="p-0">{{ selectedForm.length ? `${selectedForm.length} Selected` : `Select thumbnails for bulk action`}}</h5>
                            </label>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="bulkActionDropdown2 = !bulkActionDropdown2" v-click-outside="closeBulkActionDropdown2" v-if="selectedForm.length">
                            Bulk Action<i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="bulkActionDropdown2 ? 'active' : ''">
                                <ul>
                                    <li v-if="companyUserCan('create', 'forms')" @click="handleDuplicateForm(selectedForm)">Duplicate</li>
                                    <li v-if="companyUserCan('delete', 'forms')" class="danger" @click="handleDeleteForm(selectedForm)">Delete</li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="actionList2 = !actionList2"  v-click-outside="closeSortingDropdown2">
                            {{ filterTitle }} <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="actionList2 ? 'active' : ''">
                                <ul>
                                    <li v-for="(filter, f) in filters" :key="f" :class="{'active' : filter.title == filterTitle}" @click="sortingFilter(filter)">
                                        {{ filter.title }}
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="modal secondary video_player" v-if="tutorial">
            <div class="modal_container">
                <div class="modal_header">
                    <button class="close_btn" @click="tutorial = false"><i class="fas fa-times"></i></button>
                </div>
                <div class="video_wpr">
                    <iframe src="https://www.loom.com/embed/3fe4468eeec34adfb91620693a80cd45?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                </div>
                <div class="modal_footer">
                    <a href="https://help.thrivecoach.io/" target="_blank" class="more_tutorial">More Tutorials <i class="fas fa-long-arrow-alt-right"></i></a>
                </div>
            </div>
        </div>
        <edit-thumbnail-asset v-model="editThumbnail" title="Form" :selected-asset="activeForm" :refresh-asset="refreshForm" />
        <sort-asset v-model="sortForm" title="Form" asset-type="forms" :update-sorting="sortingFilter" :folder-id="params.folder_id" />
        <new-form v-model="newForm"/>
        <import-share-link v-model="importForm" title="Form" :refresh-asset="refreshForm" :has-saved-styling="true" />
        <rename-form v-model="renameForm" :active-form="activeForm" />
        <send-form v-model="sendForm" :active-form="activeForm" />
        <asset-link v-model="formLink" title="Form" :selected-asset="activeForm" />
        <show-analytics v-model="showAnalytics" :active-form="activeForm" />
        <share-component v-model="shareForm" title="Form" :selected-asset="activeForm" />
        <move-asset v-model="moveForm" title="Form" asset-type="forms" :selected-asset="activeForm" :refresh-asset="refreshForm" />
        <share-location v-model="shareLocation" title="Form" :selected-asset="activeForm" />
        <form-respondent v-model="formRespondent" :active-form="activeForm" />
        <edit-delivery-message v-model="editDeliveryMessage" :active-form="activeForm" />
        <form-disable-page v-model="formDisablePage" :active-form="activeForm" />
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'

    const EditThumbnailAsset = defineAsyncComponent(() => import('@/components/EditThumbnailAsset'))
    const NewForm = defineAsyncComponent(() => import('@/pages/form/components/NewForm'))
    const AssetFolder = defineAsyncComponent(() => import('@/components/AssetFolder'))
    const SortAsset = defineAsyncComponent(() => import('@/components/SortAsset'))
    const AssetLink = defineAsyncComponent(() => import('@/components/AssetLink'))
    const ImportShareLink = defineAsyncComponent(() => import('@/components/ImportShareLink'))
    const RenameForm = defineAsyncComponent(() => import('@/pages/form/components/RenameForm'))
    const SendForm = defineAsyncComponent(() => import('@/pages/form/components/SendForm'))
    const ShowAnalytics = defineAsyncComponent(() => import('@/pages/form/components/ShowAnalytics'))
    const ShareComponent = defineAsyncComponent(() => import('@/components/ShareComponent'))
    const MoveAsset = defineAsyncComponent(() => import('@/components/MoveAsset'))
    const ShareLocation = defineAsyncComponent(() => import('@/components/ShareLocation'))
    const FormRespondent = defineAsyncComponent(() => import('@/pages/form/components/FormRespondent'))
    const EditDeliveryMessage = defineAsyncComponent(() => import('@/pages/form/components/EditDeliveryMessage'))
    const FormDisablePage = defineAsyncComponent(() => import('@/pages/form/components/FormDisablePage'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'

    import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

    export default {
        name: "Forms",

        data () {
            return {
                tutorialList: false,
                tutorial: false,
                filterTitle: 'Custom Arrangement',
                filters: [
                    { title: 'Custom Arrangement', field: 'ordering', order: 'desc'},
                    { title: 'A-Z', field: 'survey_name', order: 'asc'},
                    { title: 'Z-A', field: 'survey_name', order: 'desc'},
                    { title: 'Date Modified', field: 'date_modified', order: 'desc'},
                    { title: 'Date Created', field: 'id', order: 'desc'},
                    { title: 'Highest Submission Rate', field: 'submission_rate', order: 'desc'},
                    { title: 'Lowest Submission Rate', field: 'submission_rate', order: 'asc'},
                ],
                search: '',
                perPageFilter: 6,
                isTyping: false,
                newForm: false,
                actionList: false,
                actionList2: false,
                bulkActionDropdown: false,
                bulkActionDropdown2: false,
                editThumbnail: false,
                selectedForm: [],
                activeForm: {},
                folderParams: {
                    archived: 0,
                    parent_id: 0,
                },
                assetFolder: false,
                assetFolderWatcher: 0,
                sortForm: false,
                importForm: false,
                renameForm: false,
                moreOptionDropdown: false,
                isMoreOption: true,
                sendForm: false,
                formLink: false,
                showAnalytics: false,
                shareForm: false,
                moveForm: false,
                shareLocation: false,
                formRespondent: false,
                editDeliveryMessage: false,
                formDisablePage: false,
                page: 1,
                isLastBreadcrumb: false,
                showContent: false,
                searchField: false,
                mobile_filter: false,
            }
        },

        components: {
            EditThumbnailAsset,
            NewForm,
            AssetFolder,
            SortAsset,
            ImportShareLink,
            RenameForm,
            SendForm,
            AssetLink,
            ShowAnalytics,
            ShareComponent,
            MoveAsset,
            ShareLocation,
            FormRespondent,
            EditDeliveryMessage,
            FormDisablePage,
        },

        watch: {
            search (val) {
                const vm = this;

                vm.setDashboardParams({ key: 'search', value: val });

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length == 0 || vm.params.search.length >= 2) {
                            vm.setDashboardParams({ key: 'page', value: 1 });

                            vm.getForms(vm.params);
                        }
                    }
                }
            },

            perPageFilter (perPage) {
                const vm = this;

                vm.setDashboardParams({ key: 'page', value: 1 });
                vm.setDashboardParams({ key: 'per_page', value: perPage });
                vm.selectedForm = [];

                vm.getForms(vm.params);
            },

            editDeliveryMessage (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
                forms: state => state.formModule.forms,
                params: state => state.formModule.params,
                formLoader: state => state.formModule.formLoader,
                isArchived: state => state.folderModule.isArchived,
                isPreviousFolder: state => state.folderModule.isPreviousFolder,
                isAssetFolder: state => state.folderModule.isAssetFolder,
                folderId: state => state.folderModule.folderId,
                folderBreadcrumb: state => state.folderModule.folderBreadcrumb,
            }),

            ...mapGetters({
                companyUserCan: 'authModule/COMPANY_USER_CAN',
                isSuperUser: 'authModule/IS_SUPER_USER',
            }),
        },

        mounted () {
            const vm = this;

            if (vm.isPreviousFolder) {
                vm.setDashboardParams({ key: 'folder_id', value: vm.folderId });

                vm.folderParams.archived = vm.isArchived ? 1 : 0;
                vm.isLastBreadcrumb      = true;
                vm.assetFolder           = vm.isAssetFolder ? true : false;
            } else {
                vm.folderParams.archived = vm.params.archived ? 1 : 0;
            }

            vm.folderParams.parent_id = vm.params.folder_id ? vm.params.folder_id : 0;

            vm.setIsPreviousFolder(false);

            if (vm.forms.total == 0 || !vm.forms.total) {
                vm.getForms(vm.params);
            }

            if (vm.params.search) {
                vm.search = vm.params.search;
            }

            if (vm.params.per_page) {
                vm.perPageFilter = vm.params.per_page;
            }

            if (vm.params.filter_title) {
                vm.filterTitle = vm.params.filter_title;
            }

            document.getElementById('form_index').onclick = function () {
                setTimeout(() => {
                    if (!vm.isMoreOption) {
                        vm.moreOptionDropdown = 0;
                    }
                }, 10);
            }
        },

        methods: {
            ...mapActions({
                getForms: 'formModule/getForms',
                duplicateForm: 'formModule/duplicateForm',
                deleteForm: 'formModule/deleteForm',
                setEnableDisable: 'formModule/setEnableDisable',
            }),

            ...mapMutations({
                setIsArchivedStatus: 'folderModule/SET_IS_ARCHIVED_STATUS',
                setDashboardParams: 'formModule/SET_DASHBOARD_PARAMS',
                resetDashboardParams: 'formModule/RESET_DASHBOARD_PARAMS',
                setIsPreviousFolder: 'folderModule/SET_IS_PREVIOUS_FOLDER_STATUS',
            }),

            moreOption(id) {
                const vm = this;

                vm.isMoreOption       = true;
                vm.moreOptionDropdown = vm.moreOptionDropdown == id ? 0 : id;

                setTimeout(function () {
                    vm.isMoreOption = false;
                }, 100);
            },

            sortingFilter (filter) {
                const vm = this;

                vm.filterTitle     = filter.title;

                vm.setDashboardParams({ key: 'filter_title', value: filter.title });
                vm.setDashboardParams({ key: 'order_by', value: filter.field });
                vm.setDashboardParams({ key: 'order', value: filter.order });
                vm.setDashboardParams({ key: 'page', value: 1 });
                vm.getForms(vm.params);
            },

            togglePerPageFilter () {
                const vm = this;
                vm.actionList = false;
                vm.bulkActionDropdown = false;
                const filter = vm.$refs['form-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            handlePagination (page) {
                const vm = this;

                vm.page = page;

                vm.setDashboardParams({ key: 'page', value: page });
                vm.getForms(vm.params);
            },

            closePerPageDropdown () {
                const vm = this;

                const filter = vm.$refs['form-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            closeSortingDropdown () {
                const vm = this;

                vm.actionList = false;
            },
            closeSortingDropdown2 () {
                const vm = this;

                vm.actionList2 = false;
            },

            closeBulkActionDropdown () {
                const vm = this;

                vm.bulkActionDropdown = false;
            },
            closeBulkActionDropdown2 () {
                const vm = this;

                vm.bulkActionDropdown2 = false;
            },

            closeTutorialListDropdown(){
                const vm = this;

                vm.tutorialList = false;
            },

            refreshForm () {
                const vm = this;

                vm.search       = '';
                vm.selectedForm = [];

                vm.setDashboardParams({ key: 'page', value: 1 });
                vm.setDashboardParams({ key: 'search', value: '' });
                vm.setDashboardParams({ key: 'folder_id', value: vm.folderParams.parent_id ? vm.folderParams.parent_id : null });
                vm.getForms(vm.params);
            },

            handleDuplicateForm (id) {
                const vm = this;

                if (id.length) {
                    const options = Helper.swalConfirmOptions('Are you sure?', `You want to duplicate the selected ${id.length > 1 ? 'forms' : 'form'}`, 'Yes');

                    options.preConfirm = function () {
                                            return vm.duplicateForm({ id }).then((result) => {
                                                vm.search       = '';
                                                vm.selectedForm = [];

                                                vm.setDashboardParams({ key: 'search', value: '' });
                                                vm.getForms(vm.params);
                                            });
                                        };

                    Swal.fire(options);
                } else {
                    Toastr.error('Please select at least one form');
                }
            },

            handleDeleteForm (id) {
                const vm = this;

                if (id.length) {
                    const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover ${id.length > 1 ? 'these forms' : 'this form'}`, 'Delete');

                    options.preConfirm = function () {
                                            return vm.deleteForm({ id }).then((result) => {
                                                vm.refreshForm();
                                            });
                                        };

                    Swal.fire(options);
                } else {
                    Toastr.error('Please select at least one form');
                }
            },

            refreshFolder (folder) {
                const vm = this;

                vm.folderParams.archived  = folder.archived;
                vm.folderParams.parent_id = folder.parent_id;
                vm.setDashboardParams({ key: 'folder_id', value: folder.parent_id ? folder.parent_id : null });
                vm.setDashboardParams({ key: 'archived', value: folder.archived ? 1 : 0 });

                vm.refreshForm();
            },

            handleEnableDisable (e, form) {
                const vm = this;

                if (e.target.checked) {
                    const option = Helper.swalConfirmOptions('Are you sure?', 'You want to enable this form!', 'Yes', false);

                    Swal.fire(option).then((result) => {
                        if (result.isConfirmed) {
                            const params = { survey_id: form.id, enabled: 1, }

                            vm.setEnableDisable(params).then((result) => {
                                if (result) {
                                    vm.refreshForm();
                                }
                            });
                        } else {
                            e.target.checked = false;
                        }
                    });
                } else {
                    vm.formDisablePage = true;
                    vm.activeForm      = form;
                }
            },

            resetFormEnableDisable () {
                const vm = this;
                document.getElementById(`page-${vm.activeForm.id}`).checked = false;
            },

            handleShareForm (form) {
                const vm = this;

                if (form.share_count == 0) {
                    vm.shareForm = true;
                    vm.activeForm = form;
                } else if (form.share_count == 1) {
                    const options = Helper.swalWarningOptions('Attention: Sharing Restrictions', `You are currently attempting to share an asset you do not own. Please note that sharing is exclusively for distributing assets you personally own with other Thrive Coach industry peers to help them leverage Thrive Coach with their clients. <br><br> To allocate this asset to a client or contact, kindly use the 'Assign' feature. This ensures proper authorization and tracking of the assignment within the Thrive Coach system.`);

                    Swal.fire(options);
                }
            },

            showAction (ev) {
                const vm = this;

                if (ev) {
                    vm.showContent = true;
                } else {
                    vm.showContent = false;
                }
            },
        }
    }
</script>

<style scoped>
    .acton_header{
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        flex-wrap: wrap;
    }
    .result_wpr.new .actions{
        max-width: 1200px;
        width: 100%;
        margin: 20px auto 10px auto;
    }
    .playbook_list {
        list-style-type: none;
        margin: 0 -20px;
        display: flex;
        flex-wrap: wrap;
    }

    .playbook_list > li {
        padding: 20px;
        flex: 0 1 33.333%;
        min-width: 255px;
        /* width: 33.333%; */
    }

    .plybook_item {
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        line-height: 0;
        position: relative;
        height: 100%;
    }

    .replace_img {
        position: absolute;
        right: -30px;
        top:15px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 2;
        transition: all 0.3s ease-in-out;
    }

    .replace_img img {
        max-width: 16px;
        height: auto;
    }

    .plybook_item .item_img .checkbox {
        position: absolute;
        left: 15px;
        top: 15px;
    }
    .plybook_item .item_img .checkbox span{
        border-color: #8a8a8a;
    }
    .plybook_item .item_img .checkbox span i {
        color: #2c3e50;
    }

    .plybook_item .item_img {
        height: auto;
        position: relative;
        overflow: hidden;
        border-radius: 10px 10px 0 0;
    }

    .plybook_item .item_img .img {
        height: auto;
        width: 100%;
        object-fit: cover;
    }

    .plybook_item .item_img .statistics {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        /* background: linear-gradient(to right, rgba(243, 243, 243, 1), rgba(255,255,255,0.8) 70%, rgba(255,255,255,0.5)); */
        background: #f5f5f5;
        padding: 30px 15px 15px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .plybook_item .item_img .statistics li {
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #2C3E50;
        padding: 3px 0;
        text-align: left;
    }

    .plybook_item .item_img .statistics li:first-child {
        margin-bottom: auto;
    }

    .plybook_item .img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        border-radius: 12px 12px 0 0;
    }

    .plybook_item .item_ttl {
        font-size: 16px;
        line-height: 22px;
        color: #121525;
        font-weight: 400;
        padding: 15px 20px 0 20px;
        margin-bottom: 15px;
        text-align: left;
        display: block;
        text-decoration: none;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .plybook_item .item_info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 20px 20px;
    }

    .plybook_item .item_info .member {
        font-size: 13px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
        display: flex;
        align-items: center;
    }

    .plybook_item .item_info img {
        margin-right: 5px;
        max-height: 16px;
        width: auto;
    }

    .plybook_item .item_info > ul {
        list-style-type: none;
        display: flex;
        align-items: center;
    }

    .plybook_item .item_info > ul > li {
        width: 30px;
        height: 30px;
        font-size: 15px;
        color: #2f7eed;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #f3f3f3;
        margin-left: 8px;
        cursor: pointer;
        position: relative;
    }

    .plybook_item .item_info .row_option {
        bottom: -10px;
        top: auto;
        animation: dropMove2 .5s ease-in-out;
    }

    .plybook_item .item_info .row_option:before {
        bottom: 18px;
        top: auto;
        left: auto;
        transform: rotate(45deg) scale(1);
    }

    .plybook_item .item_img:hover .statistics, .plybook_item .item_img.active .statistics {
        opacity: 1;
    }
    .plybook_item .item_img:hover .replace_img{
        right: 15px;
    }

    @keyframes dropMove2  {

        from  {margin-bottom: 10px; opacity: 0;}

        to  {margin-bottom: 1px; opacity: 1;}
    }


    :deep(.share_playbook .modal_container) {
        height: 640px;
    }

    :deep(.share_playbook .modal_container .modal_header) {
        padding: 20px 30px 0;
    }
    :deep(.share_playbook .modal_container .modal_body) {
        padding: 0 30px;
        flex-direction: column;
    }
    :deep(.share_playbook .modal_container .modal_footer) {
        border-radius: 0 0 12px 12px;
    }

    .share_playbook .tab_row {
        padding: 20px 0 0 0;
        display: flex;
    }

    .share_playbook .tab_row li {
        padding: 8px 20px;
        position: relative;
        cursor: pointer;
    }

    .share_playbook .tab_row li.active {
        background: #eaeaea;
        border-radius: 5px 5px 0 0;
    }

    .share_playbook .tab_wpr {
        width: 100%;
    }

    .share_playbook .result_wpr  {
        width: 100%;
    }

    table button {
        padding: 3px 10px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 400;
        background: #f2a31e;
        border-radius: 10px;
        color: #fff;
        margin-left: 7px;
    }

    table button.success {
        background: #00aa14;
    }

    table button.failed {
        background: #f04438;
    }

    .modal_footer {
        border-radius: 0 0 12px 12px;
    }

    .folder_list {
        display: flex;
        margin-top: 20px;
    }

    .folder_list li {
        width: 33.333%;
    }

    .sorting_card li {
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
    }

    .sorting_card li img {
        width: 60px;
        height: 60px;
        object-fit: cover;
    }

    .sorting_card .card_details {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #121525;
        padding: 10px 15px;
    }

    .sorting_card .card_details p {
        font-size: 10px;
        line-height: 13px;
        font-weight: 400;
        color: #5a5a5a;
        margin-top: 3px;
    }

    .sorting_card li:hover {
        background: #fff;
        box-shadow: 0px 0px 44px rgba(0,0,0,0.13);
    }


    .result_wpr.small .actions > ul {
        height: 35px;
    }

    .result_wpr.small .actions > ul li, .result_wpr.small .actions > ul li input {
        padding: 7px 10px;
        font-size: 14px !important;
    }

    .result_wpr.small .actions > ul li .dropdown_wpr {
        width: 100%;
    }

    .result_wpr.small table td  {
        padding: 12px;
        font-size: 13px;
        line-height: 16px;
    }

    .result_wpr.small table th {
        padding: 7px 12px;
        font-size: 13px;
        line-height: 16px;
    }

    .result_wpr.small table td .user_img, .result_wpr.small table td .user_img img {
        width: 30px;
        height: 30px;
    }

    .result_wpr.small table td h4  {
        font-size: 13px;
        line-height: 16px;
        color: #2f7eed;
        font-weight: 500;
    }

    .result_wpr table.standard td:first-child, .result_wpr table.standard th:first-child {
        width: auto;
        text-align: left;
    }

    .row_option li a {
        color: inherit;
        text-decoration: none;
    }

    .action_list {
        display: flex;
        flex-wrap: wrap;
    }

    .action_list li {
        margin-right: 5px;
        cursor: pointer;
    }

    .action_list li i.fa-envelope {
        color: #f2a31d;
    }

    .action_list li i.fa-reply {
        color: #2f7eed;
    }

    .action_list li i.fa-trash-alt {
        color: #eb1414;
    }

    .folders_list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 0 -5px;
    }

    .folders_list li {
        padding: 0 5px;
    }

    .folders_list .folder_item {
        border: 1px solid #E9E9E9;
        padding: 10px 15px;
        border-radius: 9px;
        background: #fff;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        font-size: 13px;
        line-height: 17px;
        color: #5A5A5A;
        font-weight: 400;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        text-align: center;
        height: 100%;
        cursor: pointer;
    }

    .folders_list .folder_item img {
        max-width: 30px;
        height: auto;
        margin-right: 10px;
    }

    .folders_list .folder_item:hover {
        box-shadow: 0px 0px 44px rgba(0,0,0,0.13)
    }

    .folders_list .folder_item .drp_wrapper {
        display: none;
    }

    .folders_list .folder_item .dropdown {
        margin-left: 20px;
    }

    .folders_list .folder_item .dropdown.active .drp_wrapper {
        display: block;
    }

    .folders_list .folder_item .dropdown.active .drp_wrapper ul {
        padding: 5px 0;
    }

    .folders_list .folder_item .dropdown.active .drp_wrapper ul li {
        font-size: 10px;
        line-height: 12px;
        padding: 4px 8px;
        text-align: left;
    }

    .modal.redirect_modal .modal_container {
        height: 420px;
    }

    .modal.redirect_modal .section_ttl {
        font-size: 16px;
        line-height: 20px;
        color: #5a5a5a;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        width: 100%;
        margin: 0 0 10px;
    }

    .modal.redirect_modal .section_ttl span {
        padding: 0 10px;
        background: #fff;
    }

    .modal.redirect_modal .section_ttl:after {
        content: '';
        position: absolute;
        left: 7px;
        right: 7px;
        top: 50%;
        border-bottom: 1px solid #e9e9e9;
        z-index: -1;
    }

    .redirect_list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .redirect_list li {
        width: 33.333%;
        padding: 5px 8px;
    }

    .redirect_list li label h5 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        margin: 0;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        padding: 10px;
        cursor: pointer;
        background: rgba(242, 163, 29, 0.05);
        border: 1px solid rgba(242, 163, 29, 0.3);
        color: #757575;
        filter: grayscale(1);
    }

    .redirect_list li label input[type=radio]:checked ~ h5 {
        filter: grayscale(0);
        color: #f2a31d;
    }

    .redirect_action {
        width: 100%;
        padding: 0 8px;
    }

    .modal.redirect_modal .action_wpr {
        margin-top: auto;
    }

    .ques_listing li {
        font-size: 14px;
        line-height: 19px;
        list-style-type:decimal;
        margin-left: 17px;
        padding: 10px 0 10px 5px;
        border-bottom: 1px solid #e9e9e9;
    }


    .add_template .add_types ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
    }

    .add_template .add_types ul li {
        padding: 10px;
        width: 180px;
    }

    .close_btn  {
        border: 0;
        background: rgba(50,55,59,.6);
        font-size: 12px;
        color: #fff;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }

    .tags {
        margin-top: 16px;
        height: 20px;
        width: auto;
        padding: 4px 10px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 400;
        background: #999;
        color: #fff;
        border-radius: 3px;
        margin-left: auto;
    }

    /* .acton_header h2 {
        font-size: 30px;
        line-height: 36px;
        color: #121525;
        font-weight: 400;
    } */
    .header_filter{
        position: fixed;
        top: 0;
        bottom: 0;
        background: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
        width: 250px;
        overflow-y: auto;
        right: -250px;
        z-index: 12;
        transition: all 0.3s ease-in-out;
        text-align: left;
    }
    .header_filter.show{
        right: 0;
    }
    .header_filter .close_btn{
        position: absolute;
        right: 20px;
        top: 20px;
        width: 20px;
        height: 20px;
        font-size: 10px;
        border-radius: 50%;
        background: #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        cursor: pointer;
    }
    .header_filter .top_area{
        padding: 20px;
    }
    .header_filter .top_area h3{
        font-size: 17px;
        line-height: 22px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 15px;
    }
    .header_filter .search_area {
        padding: 0 31px 0 0;
        border-radius: 17px;
        background: #f5f5f5;
        position: relative;
        margin: 15px 0;
    }
    .header_filter .search_area input[type=text] {
        font-size: 11px;
        height: 30px;
        width: 100%;
        background: transparent;
        padding: 0 0 0 15px;
        transition: all 0.3s ease-in-out;
    }
    .header_filter .search_area .search_btn {
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-size: 11px;
        color: #121525;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .header_filter .top_area ul {
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 5px;
    }
    .header_filter .top_area ul li {
        height: 30px;
        width: 30px;
        background: #2f7eed;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02);
        margin-right: 6px;
    }
    .header_filter .top_area ul li img {
        max-width: 16px;
        height: auto;
        filter: brightness(100);
    }
    .header_filter .action_area{
        padding: 20px;
        border-top: 1px solid #e9e9e9;
    }
    .header_filter .action_area ul li .checkbox h5 {
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #121525;
        margin: 5px 0;
    }
    .header_filter .action_area > ul > li {
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
        text-align: left;
        /* background: #f5f5f5; */
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        margin-bottom: 10px;
    }
    .header_filter .action_area > ul > li.optionDrops{
        padding: 7px 15px;
        border: 1px solid #e9e9e9;
        border-radius: 20px;
    }
    .header_filter .action_area > ul > li > i{
        margin-left: auto;
    }
    .header_filter .action_area .dropdown_wpr ul{
        max-height: 250px;
        overflow-y: auto;
    }
    .header_filter .action_area .dropdown_wpr ul li{
        border: 0;
    }
    .header_filter .action_area .dropdown_wpr ul li.active{
        background: #f5f5f5;
    }
    .result_wpr.new .actions > ul li.filter_btn{
        background: #fff;
        width: 30px;
        height: 30px;
        border-radius: 3px;
        padding: 4px 6px;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        cursor: pointer;
    }
    .filter_btn span{
        height: 2px;
        width: 100%;
        border-radius: 2px;
        background: #2f7eed;
        margin: 2px 0;
    }
    .filter_btn span:nth-child(2){
        width: 60%;
    }
    /* @media(max-width: 1199px){
        .acton_header{
            flex-wrap: wrap;
        }
        .acton_header .left_section, .acton_header .right_section{
            width: 100%;
        }
        .acton_header .right_section{
            margin-top: 25px;
        }
        .acton_header .left_section .search_area, .acton_header .right_section > ul > li.add_btn{
            margin-left: auto;
        }
        .acton_header .search_area input, .acton_header .search_area .search_btn{
            height: 30px;
        }
    } */
    @media(max-width: 1199px){
        .acton_header .search_area input{
            width: 200px;
            height: 30px;
        }
        .acton_header .search_area .search_btn{
            height: 30px;
        }
        .acton_header .left_section .search_area{
            display: none;
        }
        .acton_header .right_section > ul > li:not(.add_btn){
            display: none;
        }
        .result_wpr.new .actions > ul > li:not(.tutorial_btn, .sort_list, .filter_btn){
            display: none;
        }
        .result_wpr.new .actions > ul li.filter_btn{
            display: flex;
        }
    }
    /* @media(max-width: 767px){
        .acton_header {
            gap: 0;
        }
        .acton_header .search_area input{
            width: 170px;
        }
        .acton_header .right_section, .result_wpr.new .actions{
            margin-top: 15px;
        }
        .result_wpr.new .actions > ul li button.video_btn{
            margin-left: 10px;
        }
        .result_wpr.new .actions > ul.left, .result_wpr.new .actions > ul.right{
            width: calc(100% + 14px);
        }
        .result_wpr.new .actions > ul.left li.tutorial_btn, .result_wpr.new .actions > ul.right li.sort_list{
            margin-left: auto;
        }
        .tutorial_btn .dropdown_wpr{
            right: 1px;
            left: auto;
        }
    } */
    @media(min-width: 1200px){
        .header_filter{
            display: none;
        }
    }
</style>
